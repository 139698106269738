import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Avatar, Menu, MenuItem } from "@mui/material";
import { useKeycloak } from '@react-keycloak/web';
import User from '../../../../../../../../models/user';

export default function UserAvatar({onLogout}) {
    const {keycloak} = useKeycloak()
    const user = new User(keycloak.token, keycloak.tokenParsed)

    const [anchorEl, setAnchorEl] = useState(null);

    const stringAvatar = (name) => {
        return {
          sx: {
            bgcolor: stringToColor(user.name),
          },
          children: `${name.split('')[0]}`,
        };
    }

    const stringToColor = (string) => {
        let hash = 0;
        let i;
        let color = '#';
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.substr(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
    }
    
    const handleMenu = (event) => { setAnchorEl(event.currentTarget); };
    const handleLogout = () => { onLogout(); };
    const handleClose = () => { setAnchorEl(null); };

    return (
        <div>
            <Avatar
                style={{ cursor: 'pointer' }} 
                {...stringAvatar(user?.name?.toUpperCase())} 
                aria-label="Mi cuenta"
                aria-controls="user-menu"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit" />
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}>

                <Link to={"/mi-cuenta"} title="Mi cuenta">
                    <MenuItem onClick={handleClose}>
                        Mi cuenta
                    </MenuItem>
                </Link>

                <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
            </Menu>
        </div>
    );
}