class User {
    constructor(token, parsedToken) {
        this.token = token
        this.name = parsedToken.name
        this.email = parsedToken.email
        this.locale = parsedToken.locale
        this.username = parsedToken.preferred_username
        this.company = parsedToken.company
        this.roles = parsedToken['resource_access'].facturas.roles
    }
}

export default User;