import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './services/keycloak.js';

const keycloakProviderInitConfig = {
  enableLogging: true,
  checkLoginIframe: false,
  flow: 'standard',
  onLoad: 'check-sso',
  onAuthSuccess: (authenticated) => {
    // This is triggered when authentication is successful
    console.log('Authenticated:', authenticated);
    // Handle any additional logic after successful authentication here
  }
};
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
  
root.render(
  <ReactKeycloakProvider authClient={keycloak} initOptions={keycloakProviderInitConfig}>
  <StrictMode>
      {/* <BrowserRouter location={history.location} navigator={history}> */}
        <App />
      {/* </BrowserRouter> */}
  </StrictMode>
  </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
