import React, { memo } from "react";
import { useState } from "react";
import { isEqual } from 'lodash';
import { SidebarData } from "./SidebarData";
import './Navbar.scss';
import logo from '../../../../../../images/logo_white.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import UserAvatar from "../Navbar/components/UserAvatar";
import { useKeycloak } from '@react-keycloak/web';
import User from '../../../../../../models/user';
const Navbar = ({
    onLogout,
}) => {
    let pathname = window.location.pathname === '/' ? 'inicio' : window.location.pathname.replace('/', '');
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    const [sideBar, setSidebar] = useState(false);
    const { keycloak } = useKeycloak();
    const user = new User(keycloak.token, keycloak.tokenParsed)

    const handleClickMenuItem = (path) => { navigate(path); }
    const showSidebar = (show) => { setSidebar(show); }

    const NavItem = memo(({children}) => {
        return (
            <span>
                {children}
            </span>
        )
    }, isEqual)

    return (
        <>
            <div className="navbar-sidebar">
                <Link to="#" className="menu-bars" onMouseEnter={() => showSidebar(true)}>
                    <FontAwesomeIcon icon={faBars} size="2x" color="#215732" />
                </Link>

            </div>
            <nav className={sideBar ? "nav-menu active" : "nav-menu"} onMouseLeave={() => showSidebar(false)}>
                <ul className="nav-menu-items">
                    <li className="mb-3">
                        <div className="w-100 d-flex align-items-center justify-content-end">
                            <div className="navbar-brand">
                                <img src={logo} className="brand" style={{height: '50px'}} alt="Recomotor" />
                            </div>
                        { <UserAvatar style={{ cursor: 'pointer' }} onLogout={onLogout} /> }
                        </div>
                    </li>
                    { SidebarData.map((item, index) => {
                        if(
                            item.permission === currentUser?.rol_id || 
                            (item.permission === 0) || 
                            (user.roles.includes("admin") || currentUser?.rol_id === 7)
                        ) {
                            return (
                                <NavItem key={index}>
                                    <span
                                        onClick={() => handleClickMenuItem(item.path)}
                                        className={`${item.cName} 
                                        ${item.title.toLowerCase() === pathname ? "active" : ""}`}>
                                            <li style={{ cursor: 'pointer' }}>
                                                <span>{item.title}</span>
                                            </li>
                                    </span>
                                </NavItem>
                            )
                        } else {
                            return '';
                        }
                    })}
                    
                </ul>
            </nav>
        </>
    );
}

export default memo(Navbar)