import { Navigate, Route, RouterProvider, Routes, useNavigate } from "react-router-dom";
import './styles/main.scss';

import './styles/main.scss';
import { router } from './routes/routes';

function App() {

  return (
    <RouterProvider router={router} />
  );
}

export default App;
