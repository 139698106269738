import { useKeycloak } from '@react-keycloak/web';
import PropTypes from 'prop-types';
import API from '../../services/api';
// import useToken from '../../useToken';
// import User from '../../data/user';

const ProtectedRoute = ({ children }) => {

    const { keycloak, initialized } = useKeycloak();

    if (!initialized) {
        return <div></div>;
    }

    if (!keycloak.authenticated) {
        keycloak.login()
        return null;
    }
    // if(keycloak.authenticated) {
        // const user = new User(keycloak.token, keycloak.tokenParsed)
        // setToken(user);
    // }

    if (keycloak?.token) {

        API.interceptors.request.use(
            (config) => {
                config.headers['Authorization'] = `Bearer ${keycloak.token}`;
                return config;
            },
            (error) => Promise.reject(error)
        );

        API.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                    keycloak.logout();
                }
                return Promise.reject(error);
            }
        );
    }
    return children;
};

ProtectedRoute.propTypes = {
    children: PropTypes.any.isRequired,
};

export default ProtectedRoute;
