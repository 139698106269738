
import { Route, Routes, Navigate, Outlet } from "react-router-dom";

import Config from "./components/shared/components/Config";
import Navbar from "./components/shared/components/Navbar";
import { useKeycloak } from '@react-keycloak/web';

const Main = ({onLogout, usuario}) => {

     const { keycloak } = useKeycloak();
      
      const handleLogout = () => {
        
        keycloak.logout();
      }
      return (
        <div className="dashboard-container">
            <Navbar onLogout={handleLogout} usuario={usuario} />

            <Config />
            <Outlet/>

        </div> 
    );
}

/**

 */

export default Main