import axios from 'axios';
import { refresh } from '../auth';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  },
});

// API.interceptors.request.use(
//   (config) => {
//     const currentUser = JSON.parse(localStorage.getItem('user_fact'));
//     if (currentUser && currentUser.token) {
//       config.headers.authorization =  `Bearer ${currentUser.token}`;
//     }
//     return config;
//   },
//   (error) => Promise.reject(error),
// );

API.interceptors.response.use((response) => {
  return response
}, async function (error) {
  
  // Logout user if token refresh didn't work or user is disabled
  if (error.response.status === 403 && error.response.data === 'You dont have access to this resource') {
    localStorage.setItem('user_fact', null);
    window.location.href="/";

    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

    return new Promise((resolve, reject) => {
      reject(error);
    });
  
});

export default API;