
import { createBrowserRouter } from 'react-router-dom';
import { Navigate } from "react-router-dom";
// import MainLayout from '../views/MainLayout';
// import ReglasDesguaces from '../views/MainLayout/ReglasDesguaces';
// import ReglasPiezas from '../views/MainLayout/ReglasPiezas';
// import TablaPricing from '../views/MainLayout/TablaPricing';
// import LogsPage from '../views/MainLayout/LogsPage';

import ProtectedRoute from './ProtectedRoute';
import Main from '../views/Main';
import { useKeycloak } from '@react-keycloak/web';
import Inicio from '../views/Main/components/Inicio';
import MovsView from '../views/Main/components/Movimientos';
import FactsView from '../views/Main/components/Facturas';
import AbonosView from '../views/Main/components/Abonos';
import DashBoardView from '../views/Main/components/Dashboard';
import MicuentaView from '../views/Main/components/Micuenta';
// import Navigate from '../views/Main/components/'
import BackOfficeView from '../views/Main/components/Backoffice';

import React from 'react';


// import Login from './views/Login';

export const router = createBrowserRouter([
    {
        path: '/',
        element: <ProtectedRoute ><Main /></ProtectedRoute>,
        loader: ({ request }) => {
            // console.log(request)
            return null
        },
        shouldRevalidate: () => true,
        children:[
            {path: "*", exact:true,  element: <Navigate to="/" />,},
            {path: "/", exact:true,  element: <MovsView />,},
            {path: "/cargarMovimientos", element: <Inicio />,},
            {path: "/movimientos", element:<MovsView />,},
            {path: "/facturas", element: <FactsView />,},
            {path: "/abonos", element:<AbonosView />},
            {path: "/exportadores", element: <DashBoardView />},
            {path: "/backoffice", element: <BackOfficeView />},
            {path: "/mi-cuenta", element:<MicuentaView />}
        ]
    }
]);
